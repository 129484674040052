














import { Component, Vue } from 'vue-property-decorator'
import VABox from 'va/widgets/VABox.vue'
import SelectAddresseeForm from '@/components/organisms/Receipt/Contract/SelectAddresseeForm.vue'
import ContractReceipt from '@/components/organisms/Receipt/Contract/ContractReceipt.vue'
import NoContractReceipt from '@/components/organisms/Receipt/Contract/NoContractReceipt.vue'
import menuStore from '@/store/Menu'
import contractReceiptStore from '@/store/ContractReceipt'
import organizationStore from '@/store/Organizations'

@Component({
  components: { VABox, SelectAddresseeForm, ContractReceipt, NoContractReceipt },
})
export default class extends Vue {
  get paymentContractExists(): boolean {
    return contractReceiptStore.paymentContract !== null
  }

  async created(): Promise<void> {
    menuStore.setActiveFlg({ settings: true })

    await Promise.all([contractReceiptStore.fetchContractByYearMonth(), organizationStore.fetchCurrentOrganization()])
    if (organizationStore.currentOrganization?.name !== undefined) {
      contractReceiptStore.setAddressee(organizationStore.currentOrganization?.name)
    }
  }
}
