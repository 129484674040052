import config from '@/config'
import store from '@/store'
import axios from 'axios'
import { Mutation, Action, getModule, Module, VuexModule } from 'vuex-module-decorators'
import dayjs, { Dayjs } from '@/libs/dayjs'
import { TAX_RATE } from '@/store/Purchase'

export const ErrorMsg = {
  FetchContractFailureError: '契約を取得できませんでした',
} as const

export type PaymentContract = {
  startDate: Date
  endDate: Date | null
  plan: {
    name: string
    maxGroups: number
    price: number
  }
}

@Module({ dynamic: true, namespaced: true, name: 'receipt', store })
class Mod extends VuexModule {
  addressee: string | null = null
  issueDate: string = dayjs().format('YYYY年MM月DD日')
  issueTargetYearMonth: Dayjs = dayjs()
  paymentContract: PaymentContract | null = null

  get usageStartPeriod(): string | null {
    if (this.paymentContract === null) return null
    const startOfIssueTargetMonth: Dayjs = this.issueTargetYearMonth.startOf('month')
    const contractStartDate: Dayjs = dayjs(this.paymentContract.startDate)
    return contractStartDate.isBefore(startOfIssueTargetMonth) || contractStartDate.isSame(startOfIssueTargetMonth)
      ? startOfIssueTargetMonth.format('YYYY/MM/DD')
      : contractStartDate.format('YYYY/MM/DD')
  }
  get usageEndPeriod(): string | null {
    if (this.paymentContract === null) return null
    const endOfIssueTargetMonth: Dayjs = this.issueTargetYearMonth.endOf('month')
    const formattedEndOfIssueTargetMonth = endOfIssueTargetMonth.format('YYYY/MM/DD')
    if (this.paymentContract.endDate === null) return formattedEndOfIssueTargetMonth
    const contractEndDate: Dayjs = dayjs(this.paymentContract.endDate)
    return contractEndDate.isAfter(endOfIssueTargetMonth) || contractEndDate.isSame(endOfIssueTargetMonth)
      ? formattedEndOfIssueTargetMonth
      : contractEndDate.format('YYYY/MM/DD')
  }
  get price(): string | null {
    if (this.paymentContract === null) return null
    return this.paymentContract.plan.price.toLocaleString('ja-JP')
  }
  get unformattedPrice(): number | null {
    if (this.paymentContract === null) return null
    return this.paymentContract.plan.price
  }
  get consumptionTax(): string | null {
    if (this.paymentContract === null) return null
    return Math.floor(this.paymentContract.plan.price * TAX_RATE).toLocaleString('ja-JP')
  }
  get totalAmount(): string | null {
    if (this.paymentContract === null) return null
    return (this.paymentContract.plan.price + Math.floor(this.paymentContract.plan.price * TAX_RATE)).toLocaleString(
      'ja-JP'
    )
  }

  @Mutation
  setAddressee(addressee: string | null): void {
    this.addressee = addressee
  }

  @Mutation
  setPaymentContract(paymentContract: PaymentContract | null): void {
    this.paymentContract = paymentContract
  }

  @Action({ commit: 'setPaymentContract', rawError: true })
  async fetchContractByYearMonth(): Promise<PaymentContract | null> {
    const url = `${config.enterprise.apiUrl}/contracts/${this.issueTargetYearMonth.format('YYYYMM')}`
    try {
      const res = await axios.get(url)
      if (Object.keys(res.data).length === 0) return null

      return res.data
    } catch (e) {
      throw new Error(ErrorMsg.FetchContractFailureError)
    }
  }
}

export default getModule(Mod)
