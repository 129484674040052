











import { Component, Vue } from 'vue-property-decorator'
import contractReceiptStore from '@/store/ContractReceipt'

@Component({ components: {} })
export default class extends Vue {
  get addressee(): string | null {
    return contractReceiptStore.addressee
  }

  changeAddressee(e: Event): void {
    contractReceiptStore.setAddressee((e.target as HTMLSelectElement).value)
  }
}
