














import { Component, Vue } from 'vue-property-decorator'
import contractReceiptStore from '@/store/ContractReceipt'

@Component({ components: {} })
export default class extends Vue {
  get issueTargetYearMonth(): string {
    return contractReceiptStore.issueTargetYearMonth.format('MM')
  }

  move(): void {
    this.$router.push({
      name: 'Contract',
    })
  }
}
