
























































































import { Component, Vue } from 'vue-property-decorator'
import pdfMake from 'pdfmake/build/pdfmake'
import { TDocumentDefinitions, StyleDictionary, Content } from 'pdfmake/interfaces'
import contractReceiptStore from '@/store/ContractReceipt'
import config from '@/config'

@Component({ components: {} })
export default class extends Vue {
  title = '領収書'
  dearName = '御中'
  companyName = '株式会社ヒューマンリライトケア'
  departMentName = ''
  address = '東京都練馬区石神井町4-3-15 カーサ・ヴェルデ石神井公園1F'
  mobilePhoneNumber = '080-8303-0444'
  executive = '担当者名 堂本'
  receiptTableHeaders = {
    date: '日付',
    serviceName: '品名',
    maxGroups: '最大グループ数',
    money: '金額（円）',
    beforeTax: '小計',
    consumptionTax: '消費税(税率10％)',
    totalAmount: '合計',
  }
  thanksMessage = 'ご利用ありがとうございます。'
  remark = '但し、クレジットカード利用'
  firstAttentionMessage = '※クレジットカード決済の場合には、金銭又は有価証券の需要事実がありませんので'
  secondAttentionMessage = '表題が「領収書」になっていても印紙税法基本通達17号の1文書には該当しません。'
  thirdAttentionMessage = '※5万円を超えていても収入印紙は貼付されません。'
  onduServiceName = 'OND’Careエンタープライズサービス利用料'
  onduOtameshiServiceName = 'OND’Careエンタープライズサービス利用料(お試し期間)'

  get descriptionMessage(): string {
    return `${this.issueTargetMonth}月分のご利用明細は下記の通りです。`
  }
  get addressee(): string | null {
    return contractReceiptStore.addressee
  }
  get issueDate(): string {
    return contractReceiptStore.issueDate
  }
  get issueTargetMonth(): string {
    return contractReceiptStore.issueTargetYearMonth.format('MM')
  }
  get usageStartPeriod(): string | null {
    return contractReceiptStore.usageStartPeriod
  }
  get usageEndPeriod(): string | null {
    return contractReceiptStore.usageEndPeriod
  }
  get maxGroups(): number | null | undefined {
    return contractReceiptStore.paymentContract?.plan.maxGroups
  }
  get price(): string | null {
    return contractReceiptStore.price
  }
  get consumptionTax(): string | null {
    return contractReceiptStore.consumptionTax
  }
  get totalAmount(): string | null {
    return contractReceiptStore.totalAmount
  }
  get isOtameshi(): boolean {
    // NOTE: 「お試しプラン」を一意に特定するidが存在しない
    // プラン名で判定できるが、プラン名変更時に対応できないため金額で判定している
    // 現状金額が0円になるケースはお試しプランのみである
    return contractReceiptStore.unformattedPrice === 0
  }
  get serviceName(): string {
    return this.isOtameshi ? this.onduOtameshiServiceName : this.onduServiceName
  }

  displayPdf(): void {
    const docDefinition: TDocumentDefinitions = {
      info: this.definePdfInfo(),
      defaultStyle: this.definePdfDefaultStyle(),
      content: this.definePdfContent(),
      styles: this.definePdfStyles(),
    }

    pdfMake.fonts = this.defineFonts()
    pdfMake.createPdf(docDefinition).open()
  }

  backContractScreen(): void {
    this.$router.push({ name: 'Contract' })
  }

  private definePdfInfo() {
    return {
      title: "OND'Care領収書",
    }
  }

  private definePdfDefaultStyle() {
    return {
      font: 'GenShinGothic',
      fontSize: 10,
    }
  }

  private definePdfContent(): Content {
    return [
      {
        text: this.title,
        style: ['fontSize16', 'textBold'],
      },
      {
        text: this.issueDate,
        style: ['alignmentRight'],
      },
      {
        text: `${this.addressee} ${this.dearName}`,
        lineHeight: 1.2,
        decoration: 'underline',
        style: ['fontSize16', 'textBold'],
      },
      {
        text: this.companyName,
        style: ['alignmentRight'],
      },
      {
        text: this.departMentName,
        style: ['alignmentRight', 'mgBottom10'],
      },
      {
        text: this.address,
        style: ['alignmentRight'],
      },
      {
        columns: [
          [
            {
              stack: [this.thanksMessage, this.descriptionMessage],
            },
          ],
          {
            text: this.mobilePhoneNumber,
            width: 'auto',
            style: ['alignmentRight'],
          },
        ],
      },
      {
        text: this.executive,
        alignment: 'right',
        style: ['alignmentRight', 'mgBottom30'],
      },
      {
        layout: 'lightHorizontalLines',
        table: {
          headerRows: 1,
          widths: [55, '*', 90, 75],
          body: [
            [
              { text: this.receiptTableHeaders.date, style: ['alignmentCenter'] },
              { text: this.receiptTableHeaders.serviceName, style: ['alignmentCenter'] },
              { text: this.receiptTableHeaders.maxGroups, style: ['alignmentCenter'] },
              { text: this.receiptTableHeaders.money, style: ['alignmentCenter'] },
            ],
            [
              { text: this.usageStartPeriod, style: ['mgTop8', 'alignmentCenter'] },
              [
                {
                  text: this.serviceName,
                  style: ['alignmentCenter'],
                },
                {
                  text: `${this.usageStartPeriod}~${this.usageEndPeriod}`,
                  style: ['alignmentCenter'],
                },
              ],
              { text: this.maxGroups, style: ['mgTop8', 'alignmentCenter'] },
              { text: this.price, style: ['alignmentRight'], margin: [0, 8, 15, 0] },
            ],
            ['', '', this.receiptTableHeaders.beforeTax, { text: this.price, style: ['alignmentRight', 'mgRight15'] }],
            [
              '',
              '',
              this.receiptTableHeaders.consumptionTax,
              { text: this.consumptionTax, style: ['alignmentRight', 'mgRight15'] },
            ],
            [
              '',
              '',
              this.receiptTableHeaders.totalAmount,
              { text: this.totalAmount, style: ['alignmentRight', 'mgRight15'] },
            ],
          ],
        },
        style: ['mgBottom10'],
      },
      {
        text: this.remark,
        style: ['alignmentRight', 'mgBottom20'],
      },
      {
        stack: [this.firstAttentionMessage, { text: this.secondAttentionMessage, margin: [10, 0, 0, 20] }],
      },
      {
        text: this.thirdAttentionMessage,
      },
    ] as Content
  }

  private definePdfStyles(): StyleDictionary {
    return {
      fontSize16: {
        fontSize: 16,
      },
      textBold: {
        bold: true,
      },
      alignmentRight: {
        alignment: 'right',
      },
      alignmentCenter: {
        alignment: 'center',
      },
      mgTop8: {
        margin: [0, 8, 0, 0],
      },
      mgBottom10: {
        margin: [0, 0, 0, 10],
      },
      mgBottom20: {
        margin: [0, 0, 0, 20],
      },
      mgBottom30: {
        margin: [0, 0, 0, 30],
      },
      mgLeft15: {
        margin: [0, 0, 15, 0],
      },
      mgRight15: {
        margin: [0, 0, 15, 0],
      },
    } as StyleDictionary
  }

  private defineFonts() {
    return {
      GenShinGothic: {
        normal: `${config.enterprise.clientUrl}/GenShinGothic-Normal.ttf`,
        bold: `${config.enterprise.clientUrl}/GenShinGothic-Normal.ttf`,
        italics: `${config.enterprise.clientUrl}/GenShinGothic-Normal.ttf`,
        bolditalics: `${config.enterprise.clientUrl}/GenShinGothic-Normal.ttf`,
      },
    }
  }
}
